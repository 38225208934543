import React from 'react';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import {
    FacebookOutlined,
    InstagramOutlined,
    EnvironmentOutlined,
    ClockCircleOutlined,
    SendOutlined
} from '@ant-design/icons';

import Images from '@Src/constants/images';
import styles from './Footer.module.scss';

const Footer = () => (
    <section className="mt-5 pt-16 px-10 border-t-2 border-gray-400 mb-10 xl:px-28 2xl:px-40">
        <div className="container bg-cover overflow-x-hidden max-w-screen-xl flex flex-col sm:flex-row gap-2 justify-around">
            <div className="w-full p-2 sm:w-[200px] lg:w-[330px]">
                <div
                    className={classnames(
                        'p-2 mb-2 relative border-b-4 border-gray-400',
                        styles['title-footer']
                    )}
                >
                    <Image src={Images.LOGO} alt="logo-landing-page" width={50} height={50} />
                </div>
                <div className="mb-2">
					Mục tiêu trở thành công ty công nghệ hàng đầu Việt Nam. Tạo ra giải pháp quản lý bán hàng
					và CTV, tối ưu chi phí vận chuyển hàng cho doanh nghiệp
                </div>
                <div className="flex gap-2.5">
                    <FacebookOutlined />
                    <InstagramOutlined />
                </div>
            </div>

            <div className="w-full p-2  sm:w-[200px] lg:w-[200px]">
                <div
                    className={classnames(
                        'p-2 mb-2 relative border-b-4 border-gray-400 text-3xl font-bold sm:p-[11px]',
                        styles['title-footer']
                    )}
                >
					Danh mục
                </div>
                <div className="flex flex-col gap-y-2">
                    <Link href="/#service">
                        <a>Mô hình Eoty - Tech</a>
                    </Link>
                    <Link href="/#about">
                        <a>Trước kia &#38; Hiện tại</a>
                    </Link>
                    <Link href="/#support">
                        <a>Giải đáp</a>
                    </Link>
                    <Link href="/#register">
                        <a>Đăng kí</a>
                    </Link>
                </div>
            </div>

            <div className="w-full p-2 sm:w-[200px] lg:w-[265px]">
                <div
                    className={classnames(
                        'p-2 sm:p-[11px] mb-2 relative border-b-4 border-gray-400 text-3xl font-bold',
                        styles['title-footer']
                    )}
                >
					Liên hệ
                </div>
                <div className="flex flex-col gap-y-2">
                    <div className="flex items-center gap-2.5">
                        <ClockCircleOutlined />
                        <span>08h00 đến 17h00 (thứ 2 đến thứ 7)</span>
                    </div>
                    <div className="flex items-center gap-2.5">
                        <SendOutlined />
                        <span>hi@eoty.vn</span>
                    </div>
                    <div className="flex items-center gap-2.5">
                        <EnvironmentOutlined />
                        <span>39C Bình Phú, phường Tam Phú, Thành phố Thủ Đức, TP. HCM</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Footer;
