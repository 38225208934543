import post1 from '@Assets/images/service/post1.png';
import post2 from '@Assets/images/service/post2.jpeg';
import post3 from '@Assets/images/service/post3.jpeg';
import logoLandingPage from '@Assets/images/logo.png';
import introLandingPage from '@Assets/images/intro-landing-page.png';
import logoIntroLandingPage from '@Assets/images/logo-intro-landing-page.png';
import avatar1 from '@Assets/images/service/avt1.png';
import avatar2 from '@Assets/images/service/avt2.png';
import avatar3 from '@Assets/images/service/avt3.png';
import avatar5 from '@Assets/images/service/avt5.png';
import avatar6 from '@Assets/images/service/avt6.png';
import avatar7 from '@Assets/images/service/avt7.png';
import avatarFeedback1 from '@Assets/images/service/feedback-avt-1.png';
import avatarFeedback2 from '@Assets/images/service/feedback-avt-2.png';
import avatarFeedback3 from '@Assets/images/service/feedback-avt-3.png';
import appLogo from '@Assets/images/service/app-logo.png';
import textLogo from '@Assets/images/service/text-logo.png';

const Images = {
    LOGO: logoLandingPage,
    INTRO_LANDING_PAGE: introLandingPage,
    LOGO_INTRO_LANDING_PAGE: logoIntroLandingPage,
    AVATAR_1: avatar1,
    AVATAR_2: avatar2,
    AVATAR_3: avatar3,
    AVATAR_5: avatar5,
    AVATAR_6: avatar6,
    AVATAR_7: avatar7,
    AVATAR_FEEDBACK_1: avatarFeedback1,
    AVATAR_FEEDBACK_2: avatarFeedback2,
    AVATAR_FEEDBACK_3: avatarFeedback3,
    LOGO_SERVICE: appLogo,
    LOGO_SERVICE_TEXT: textLogo,
    POST_1: post1,
    POST_2: post2,
    POST_3: post3
};

export default Images;
