// Libraries
import Head from 'next/head';

const HeadComp = () => (
    <Head>
        <title>EOTY Tech</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="title" content="Eoty" />
        <meta name="description" content="Eoty" />
        <meta property="title" content="Eoty" />
        <meta property="description" content="Eoty" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    </Head>
);

export default HeadComp;
