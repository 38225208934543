import type {NextPage} from 'next';

import Head from './components/Head';
import Header from './components/Header';
import Footer from './components/Footer';

type Props = {
	children: JSX.Element[] | JSX.Element;
};

const Section: NextPage = ({children}: Props) => (
    <>
        <Head />
        <Header />
        <div className="grid min-h-screen bg-white">{children}</div>
        <Footer />
    </>
);

export default Section;
