import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import {Drawer} from 'antd';
import {MenuOutlined} from '@ant-design/icons';

import Images from '@Src/constants/images';
import styles from './Header.module.scss';

const Header = () => {
    const [isShowHeader, setShowHeader] = useState(true);
    const [isShowMenu, setShowMenu] = useState(false);

    const handleScroll = (e) => {
        if (document.body.scrollTop > 75) {
            setShowHeader(false);
        } else {
            setShowHeader(true);
        }
    };

    useEffect(() => {
        document.body.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <section
                className={classnames(
                    'container mx-auto block sm:hidden top-0 py-1 w-full fixed transition-all shadow-3xl bg-white z-[100]'
                )}
            >
                <div className="flex bg-white justify-between w-full h-10 px-2">
                    <div className="relative top-0 left-[10%] z-[2]">
                        <Link href="/">
                            <Image className="cursor-pointer" src={Images.LOGO} alt="logo-landing-page" width={40} height={40} />
                        </Link>
                    </div>

                    <button
                        type="button"
                        className="inline-flex items-center justify-center
              p-3 rounded-md text-gray-400 hover:text-white hover:bg-gray-300
              focus:outline-none focus:bg-gray-600 focus:text-white
              transition duration-150 ease-in-out z-[3]"
                        onClick={() => setShowMenu(!isShowMenu)}
                    >
                        <MenuOutlined />
                    </button>
                </div>

                <Drawer
                    placement="top"
                    title={
                        <div className="flex justify-end">
                            <Link href="/">
                                <Image className="cursor-pointer" src={Images.LOGO} alt="logo-landing-page" width={40} height={40} />
                            </Link>
                        </div>
                    }
                    onClose={() => setShowMenu(!isShowMenu)}
                    visible={isShowMenu}
                    height={290}
                    headerStyle={{padding: '10px 20px'}}
                    bodyStyle={{padding: '0px 20px'}}
                >
                    <Link href="/#introduction">
                        <a
                            className="mt-4 block px-3 py-2 rounded-md font-medium hover:bg-gray-500
                focus:outline-none hover:text-white focus:text-white focus:bg-gray-700
                transition duration-150 ease-in-out"
                        >
							Trang chủ
                        </a>
                    </Link>

                    <Link href="/#service">
                        <a
                            className="mt-1 block px-3 py-2 rounded-md font-medium hover:bg-gray-500
                focus:outline-none hover:text-white focus:text-white focus:bg-gray-700
                transition duration-150 ease-in-out"
                        >
							Mô hình Eoty - Tech
                        </a>
                    </Link>

                    <Link href="/#about">
                        <a
                            className="mt-1 block px-3 py-2 rounded-md font-medium hover:bg-gray-500
                focus:outline-none hover:text-white focus:text-white focus:bg-gray-700
                transition duration-150 ease-in-out"
                        >
							Trước kia &#38; Hiện tại
                        </a>
                    </Link>

                    <Link href="/#support">
                        <a
                            className="mt-1 block px-3 py-2 rounded-md font-medium hover:bg-gray-500
                focus:outline-none hover:text-white focus:text-white focus:bg-gray-700
                transition duration-150 ease-in-out"
                        >
							Giải đáp
                        </a>
                    </Link>

                    <Link href="/#register">
                        <a
                            className="mt-1 block px-3 py-2 rounded-md font-medium hover:bg-gray-500
                focus:outline-none hover:text-white focus:text-white focus:bg-gray-700
                transition duration-150 ease-in-out"
                        >
							Đăng ký
                        </a>
                    </Link>
                </Drawer>
            </section>

            <section
                className={classnames(
                    'hidden sm:flex bg-white fixed h-[75px] w-full transition-all shadow-3xl z-[100]',
                    isShowHeader ? 'top-0' : 'top-[-75px]'
                )}
            >
                <div className="container max-w-screen-xl flex justify-between px-10">
                    <div className="flex items-center">
                        <Link href="/">
                            <Image className="cursor-pointer" src={Images.LOGO} alt="logo-landing-page" width={50} height={50} />
                        </Link>
                    </div>
                    <div className="flex items-center gap-2 md:gap-5">
                        <Link href="/#introduction">
                            <a
                                className={classnames(
                                    'relative inline-flex items-center justify-center text-center cursor-pointer w-[100px] h-full hover:text-gray-600',
                                    styles.navigator
                                )}
                            >
								Trang chủ
                            </a>
                        </Link>

                        <Link href="/#service">
                            <a
                                className={classnames(
                                    'relative inline-flex justify-center flex-col text-center cursor-pointer w-[100px] h-full hover:text-gray-600',
                                    styles.navigator
                                )}
                            >
                                <span>Mô hình</span>
                                <span>Eoty - Tech</span>
                            </a>
                        </Link>

                        <Link href="/#about">
                            <a
                                className={classnames(
                                    'relative inline-flex justify-center flex-col text-center cursor-pointer w-[100px] h-full hover:text-gray-600',
                                    styles.navigator
                                )}
                            >
                                <span>Trước kia</span>
                                <span>&#38; Hiện tại</span>
                            </a>
                        </Link>

                        <Link href="/#support">
                            <a
                                className={classnames(
                                    'relative inline-flex items-center justify-center text-center cursor-pointer w-[100px] h-full hover:text-gray-600',
                                    styles.navigator
                                )}
                            >
								Giải đáp
                            </a>
                        </Link>

                        <Link href="/#register">
                            <a className={classnames(
                                'rounded bg-blue-700 uppercase text-white hover:text-white hover:bg-blue-500 cursor-pointer px-2 py-2 w-[100px] text-center',
                                styles.register
                            )}
                            >
								Đăng ký
                            </a>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header;
